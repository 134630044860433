import Vue from 'vue'
import Vuex from 'vuex'


// Use axios as default http client
import axios from 'axios'

// Used to decode the auth jwt received
import jwt_decode from "jwt-decode";


Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    drawer: null,
    isLoggedIn: false,
    isAdminLoggedIn: false,
    authJwt: null,
    authAdminJwt: null,
    realm: null,
    userInitials: null,
    userRole: null,
    userPrivacy: 'no',
    username: null,
    adminUsername: null,
    loading: false,
    clientIpAddress: null,
    intervals: [],
    config: {
      courses: {
        search: '',
        itemsPerPage: 10,
      },
      sessions: {
        search: '',
        itemsPerPage: 10,
      }
    },
  },
  mutations: {
    SET_DRAWER(state, payload) {
      state.drawer = payload
    },
    SET_LOADING(state, payload) {
      state.loading = payload
    },

    SET_AUTH(state, payload) {
      state.isLoggedIn = payload.isAuth
    },
    SET_ADMIN_AUTH(state, payload) {
      state.isAdminLoggedIn = payload.isAuth
    },

    SET_JWT(state, payload) {
      state.authJwt = payload.jwt
    },
    SET_ADMIN_JWT(state, payload) {
      state.authAdminJwt = payload.jwt
    },
    SET_USER_ROLE(state, payload) {
      state.userRole = payload.role
    },
    SET_USERNAME(state, payload) {
      state.username = payload.username
    },
    SET_REALM(state, payload) {
      state.realm = payload.realm
    },
    SET_ADMIN_USERNAME(state, payload) {
      state.adminUsername = payload.username
    },
    SET_ADMIN_COURSESPERPAGE(state, payload) {
      state.config.courses.itemsPerPage = payload
    },
    SET_ADMIN_COURSESEARCH(state, payload) {
      state.config.courses.search = payload
    },
    SET_ADMIN_UPLOADSPERPAGE(state, payload) {
      state.config.sessions.itemsPerPage = payload
    },
    SET_ADMIN_UPLOADSEARCH(state, payload) {
      state.config.sessions.search = payload
    },
    SET_ADMIN_CONFIG(state, payload) {
      state.config = payload
    },
    SET_USER_INITIALS(state, payload) {
      state.userInitials = payload.initials
    },

  },
  actions: {
    // Action used to login and out (do we have a valid jwt?)
    login(context, payload) {
      context.commit('SET_AUTH', { isAuth: true });
      context.dispatch('storeJwt', { jwt: payload.jwt })


      // Extract from jwt initials and user role
      // Parse jwt content and fulfill some infoes
      let decoded = jwt_decode(payload.jwt);
      let username = decoded.username;
      let parts = username.split('@');
      if (parts.length === 2) {
        context.commit('SET_REALM', { realm: "@" + parts[1].toLowerCase() })
      }
      // Get initials of the user splitting on . and taking the first char of the two elements obtained

      // console.log(evaluatedInitials + ' - ' + decodedRole);
      context.commit('SET_USERNAME', { username: username });
    },
    loginAdmin(context, payload) {
      // console.log('Logged in correctly!');
      context.commit('SET_ADMIN_AUTH', { isAuth: true });
      context.dispatch('storeAdminJwt', { jwt: payload.jwt })

      // Extract from jwt initials and user role
      // Parse jwt content and fulfill some infoes
      let decoded = jwt_decode(payload.jwt);
      let username = decoded.username;
      let decodedRole = decoded.role;
      // Get initials of the user
      let evaluatedInitials = decoded.firstname[0] + decoded.lastname[0];
      // Get initials of the user splitting on . and taking the first char of the two elements obtained

      // console.log(evaluatedInitials + ' - ' + decodedRole);

      context.commit('SET_USER_INITIALS', { initials: evaluatedInitials });
      context.commit('SET_USER_ROLE', { role: decodedRole });
      context.commit('SET_ADMIN_USERNAME', { username: username });
    },
    logout(context) {
      context.commit('SET_AUTH', { isAuth: false });
      context.commit('SET_JWT', { jwt: null });
      // Delete the auth token stored
      localStorage.removeItem("upload_auth_token");
      // console.log('jwt removed from local storage!');
      // Logout cas VUE_APP_CAS_URL_LOGOUT
      // window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;

    },
    logoutAdmin(context) {
      context.commit('SET_ADMIN_AUTH', { isAuth: false });
      context.commit('SET_ADMIN_JWT', { jwt: null });
      // Delete the auth token stored
      localStorage.removeItem("upload_admin_auth_token");
      // console.log('jwt removed from local storage!');
      // Logout cas VUE_APP_CAS_URL_LOGOUT
      // window.location.href = process.env.VUE_APP_CAS_URL_LOGOUT;

    },
    // Action to store the jwt obtained by upload backend for later http request use
    storeJwt(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      localStorage.setItem('upload_auth_token', payload.jwt)
      context.commit('SET_JWT', { jwt: payload.jwt });
    },
    storeAdminJwt(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      localStorage.setItem('upload_admin_auth_token', payload.jwt)
      context.commit('SET_ADMIN_JWT', { jwt: payload.jwt });
    },
    storeAdminCoursesPerPage(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      context.commit('SET_ADMIN_COURSESPERPAGE', payload);
      localStorage.setItem('upload_admin_config', JSON.stringify(this.state.config))
    },
    storeAdminCourseSearch(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      context.commit('SET_ADMIN_COURSESEARCH', payload);
      localStorage.setItem('upload_admin_config', JSON.stringify(this.state.config))
    },
    storeAdminUploadsPerPage(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      context.commit('SET_ADMIN_UPLOADSPERPAGE', payload);
      localStorage.setItem('upload_admin_config', JSON.stringify(this.state.config))
    },
    storeAdminUploadSearch(context, payload) {
      // Store it also to the local storage (to maintain a valid session even if page reloaded)
      context.commit('SET_ADMIN_UPLOADSEARCH', payload);
      localStorage.setItem('upload_admin_config', JSON.stringify(this.state.config))
    },
    loadAdminConfig(context) {
      // Load the jwt from memory if present
      let config = localStorage.getItem("upload_admin_config");
      if (!(config === null)) {
        let decodedConfig = {};
        try {
          decodedConfig = JSON.parse(config);
          if(!decodedConfig.courses) {
            decodedConfig.courses = { search: '', itemsPerPage: 10 }
          }
          if(!decodedConfig.sessions) {
            decodedConfig.sessions = { search: '', itemsPerPage: 10 }
          }
        } catch (err) {
          decodedConfig = {
            courses: { search: '', itemsPerPage: 10 },
            sessions: { search: '', itemsPerPage: 10 },
          }

        }
        context.commit('SET_ADMIN_CONFIG', decodedConfig);
      }
    },
    // Action to store the jwt obtained by upload backend for later http request use
    loadJwt(context) {
      // Load the jwt from memory if present
      let jwt = localStorage.getItem("upload_auth_token");
      if (!(jwt === null)) {
        context.commit('SET_JWT', { jwt: jwt });
      }
    },
    loadAdminJwt(context) {
      // Load the jwt from memory if present
      let jwt = localStorage.getItem("upload_admin_auth_token");
      if (!(jwt === null)) {
        context.commit('SET_ADMIN_JWT', { jwt: jwt });
      }
    },
    // Action to store the jwt obtained by upload backend for later http request use
    validateJwt(context) {
      return new Promise((resolve, reject) => {
        // Load the jwt from memory if present
        let jwt = localStorage.getItem("upload_auth_token");
        if (!(jwt === null)) {
          // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
          // Prepare the message
          const headers = {
            Authorization: "jwt " + jwt,
          };


          //console.log(Vue);
          axios.get(process.env.VUE_APP_UPLOAD_API + "/jwtVerify", {
            headers,
          })
            .then((response) => {
              //console.log(response);
              if (response.status === 200) {
                // console.log("-> Valid token found -> autologin");
                // Valid token found, we reload the jwt user info passing from the common onValidate method
                context.dispatch('login', { jwt: jwt });
                resolve("valid");
              } else if (response.status === 404) {
                // console.log("The service is down. Please try again later...");
                //this.serviceDown = true;
                reject("servicedown");
              }
            })
            .catch((err) => {
              if (err.response) {
                // console.log(err.response.data);
              } else {
                // console.log(err);
              }
              context.dispatch('logout');
              // console.log("JWT verification error...redirectin to CAS for new authentication!");
              reject("notvalid");
            });
        }
      })
    },
    validateAdminJwt(context) {
      return new Promise((resolve, reject) => {
        // Load the jwt from memory if present
        let jwt = localStorage.getItem("upload_admin_auth_token");
        if (!(jwt === null)) {
          // Ask to backend if current found jwt is valid, if it is then use it else ask for a new jwt
          // Prepare the message
          const headers = {
            Authorization: "jwt " + jwt,
          };


          //console.log(Vue);
          axios.get(process.env.VUE_APP_UPLOAD_API + "/jwtVerify", {
            headers,
          })
            .then((response) => {
              //console.log(response);
              if (response.status === 200) {
                // console.log("-> Valid token found -> autologin");
                // Valid token found, we reload the jwt user info passing from the common onValidate method
                context.dispatch('loginAdmin', { jwt: jwt });
                resolve("valid");
              } else if (response.status === 404) {
                // console.log("The service is down. Please try again later...");
                //this.serviceDown = true;
                reject("servicedown");
              }
            })
            .catch((err) => {
              if (err.response) {
                // console.log(err.response.data);
              } else {
                // console.log(err);
              }
              context.dispatch('logoutAdmin');
              // console.log("JWT verification error...redirectin to CAS for new authentication!");
              reject("notvalid");
            });
        }
      })
    },
    // Help On Line

    // // Obtain nice asset label name starting from db column asset name
    // getNiceLabel(context, payload){

    //   // Payload = nome colonna db
    //   return context.getters(labelMapping)[payload]
    // }
  },
  modules: {
  }
})
