<template>
  <v-app>
    <v-overlay :value="this.$store.state.loading"><v-progress-circular color="primary" indeterminate :size="70" :width="7" /></v-overlay>
    <application-drawer></application-drawer>
    <top-application-bar></top-application-bar>
    <home-view></home-view>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    TopApplicationBar: () => import("./components/core/AppBar.vue"),
    ApplicationDrawer: () => import("./components/core/AppDrawer.vue"),
    HomeView: () => import("./components/core/HomeView.vue"),
  },

  data: () => ({
    //
  }),
  computed: {
    ...mapState(["loading"]),
  },
  created: function () {
    this.$store.dispatch("loadAdminConfig");
    const jwt = localStorage.getItem("upload_auth_token");
    if (!(jwt === null)) {
      // JWT exists, check for validity
      this.$store
        .dispatch("validateJwt")
        .then((status) => {
          if (status === "valid") {
            this.$store.dispatch("loadJwt");
          }
        })
        .catch(() => {
          // console.log(err);
          //console.log("invalid jwt found! Redirecting to cas");
        });
    } else {
      this.$store.dispatch("logout");
    }
    const adminJwt = localStorage.getItem("upload_admin_auth_token");
    if (!(adminJwt === null)) {
      // JWT exists, check for validity
      this.$store
        .dispatch("validateAdminJwt")
        .then((status) => {
          if (status === "valid") {
            this.$store.dispatch("loadAdminJwt");
          }
        })
        .catch(() => {
          // console.log(err);
          //console.log("invalid jwt found! Redirecting to cas");
        });
    } else {
      this.$store.dispatch("logoutAdmin");
    }
  },
};
</script>
<style lang="sass">
    @import '../node_modules/typeface-roboto/index.css'
</style>