import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'studenthome',
    component: () => import('../views/student/StudentHome.vue'),
    meta: {
      admin: false,
    }
  },
  {
    path: '/session/:sessionid',
    name: 'studentsession',
    component: () => import('../views/student/StudentSession.vue'),
    meta: {
      admin: false,
    }
  },
  {
    path: '/myuploads',
    name: 'studentupload',
    component: () => import('../views/student/StudentUploads.vue'),
    meta: {
      admin: false,
    }
  },
  {
    path: '/admin',
    name: 'admincourselist',
    component: () => import('../views/admin/AdminCourseList.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '/admin/course/:courseid',
    name: 'admincourse',
    component: () => import('../views/admin/AdminCourse.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '/admin/administrators',
    name: 'adminadministrators',
    component: () => import('../views/admin/AdminAdministrators.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '/admin/logs',
    name: 'adminlogs',
    component: () => import('../views/admin/AdminLogs.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '/admin/ipranges',
    name: 'adminipranges',
    component: () => import('../views/admin/AdminIpRanges.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '/admin/guests',
    name: 'adminguests',
    component: () => import('../views/admin/AdminGuests.vue'),
    meta: {
      admin: true,
    }
  },
  {
    path: '*',
    name: 'pagenotfound',
    component: () => import('../views/PageNotFound.vue'),
    meta: {
      admin: true,
    }
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,

  routes
})

router.beforeEach((to, from, next) => {
  // TODO check for authentication and authorization

    document.title = "Dipartimento di Informatica - UPLOAD"

  next();
})
export default router
